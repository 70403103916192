<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("cards") }}</h1>
      <br>
      <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />
  
      <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="cards">
  
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
  
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <div
            v-if="user_role ==='manager' || user_role ==='admin'"
             class="btn-add-new p-3 mb-5 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
                <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                <span class="ml-2 text-base text-primary">{{ $t("Ajouter") }}</span>
            </div>
           
          </div>
  
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ cards.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : cards.length }} of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
  
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=40">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=60">
                <span>60</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=80">
                <span>80</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
  
        <template slot="thead">
          <vs-th sort-key="id">N°Carte</vs-th>
          <vs-th sort-key="id">Code</vs-th>
          <vs-th sort-key="Nom_Prenons">{{ $t("Nom_Prenons") }}</vs-th>
<!--      <vs-th sort-key="date_naiss">{{ $t("birthdate") }}</vs-th>      
          <vs-th sort-key="Genre" v-if="user_role ==='agent'">{{ $t("gender") }}</vs-th>    --> 
          <vs-th sort-key="branches">{{ $t("branches") }}</vs-th> 
          <vs-th sort-key="Inscrits" >{{ $t("Inscrits") }}</vs-th>             
          <vs-th sort-key="expirationDate">{{ $t("expirationDate") }}</vs-th> 

         <!--  <vs-th sort-key="fullName">NOM COMPLET PDV</vs-th>
          <vs-th sort-key="dateOfIssue">{{ $t("dateOfIssue") }}</vs-th>
          <vs-th sort-key="rateHealthyCenter">{{ $t("rateHealthyCenter") }}</vs-th>
          <vs-th sort-key="rateClinic">{{ $t("rateClinic") }}</vs-th>
          <vs-th sort-key="ratePharmacy ">{{ $t("ratePharmacy") }} </vs-th>
          <vs-th sort-key="rateMedicalOffice">{{ $t("rateMedicalOffice") }}</vs-th>
          <vs-th sort-key="rateHospitals">{{ $t("rateHospitals") }}</vs-th>-->
<!--      <vs-th v-if="user_role!=='agent'"  sort-key="id">télephone agent</vs-th>
 -->      <vs-th>{{ $t("Action") }}</vs-th>
          </template>
  
          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
  
                <vs-td>
                <p class="product-name font-medium truncate">{{ tr.id}}</p>
                </vs-td>

                <vs-td>
                <p class="product-name font-medium truncate">{{ tr.code}}</p>
                </vs-td>

                <vs-td>
                <p class="product-name font-medium truncate">{{ tr.fullName}}</p>
                </vs-td>
              
                <!-- <vs-td >
                  <p class="product-name font-medium truncate">{{ tr.birthdate }}</p>
                </vs-td>
  
                <vs-td v-if="user_role ==='agent'">
                  <p class="product-name font-medium truncate" >{{  GENDERCHOICES[tr.gender] }}</p>
                </vs-td>-->
                
                <vs-td >
                  <p class="product-name font-medium truncate">{{ tr.ownerOfPdv.branch.name }}</p>
                </vs-td>
                
                <vs-td >
                  <p class="product-name font-medium truncate">{{ tr.ownerOfPdv.fullName}}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium "><vs-chip :color="getOrderStatusColor1(tr.expirationDate)" class="product-order-status">{{ tr.expirationDate}}</vs-chip></p>
                </vs-td> 
              <!--   <vs-td>
                  <p class="product-name font-medium truncate">{{tr.ownerOfPdv.fullName}}</p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">{{tr.dateOfIssue}}</p>
                </vs-td>
                
                <vs-td>
                  <p class="product-name font-medium truncate">{{tr.rateHealthyCenter}}%</p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">{{tr.rateClinic}}%</p>
                </vs-td>
                
                <vs-td>
                  <p class="product-name font-medium truncate">{{tr.ratePharmacy}}%</p>
                </vs-td>

               <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.rateMedicalOffice}}%</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.rateHospitals}}%</p>
                </vs-td>-->
               <!--  <vs-td v-if="user_role!=='agent'" >
                <p class="product-name font-medium truncate">{{ tr.author.phoneNumber}}</p>
                </vs-td> -->
                <vs-td class="whitespace-no-wrap">
                  <div class="flex">
                    <vx-tooltip v-if="user_role ==='agent' ||user_role ==='managerClient' ||user_role ==='adminClient' || user_role ==='manager' || user_role ==='admin'" text="Imprimer" color="primary">
                    <feather-icon
                      style="color: rgb(0, 4, 255)"
                        icon="PrinterIcon"
                        svgClasses="w-5 h-5 hover:text-primary stroke-current"
                        class="mr-2"
                        @click="cardPrint(tr)"
                      />
                    </vx-tooltip>
                    <vx-tooltip v-if="user_role ==='manager' || user_role ==='admin'" text="Voir ma photo" color="primary">
                      <feather-icon
                        style="color: #438eff"
                        icon="ImageIcon"
                        svgClasses="w-5 h-5 hover:text-primary stroke-current"
                        class="mr-2"
                        @click="Voirpicture(tr)"
                      />
                    </vx-tooltip>
                    <vx-tooltip v-if="user_role ==='agent' || user_role ==='manager' || user_role ==='admin'" text="Détails" color="success">
                      <feather-icon
                        style="color: #69ff43"
                        icon="EyeIcon"
                        svgClasses="w-5 h-5 hover:text-success stroke-current"
                        class="mr-2"
                        @click="VoirData(tr)"
                      />
                    </vx-tooltip>
                     <vx-tooltip v-if="user_role ==='manager' || user_role ==='admin'" :text="$t('Modifier')" color="warning">
                      <feather-icon
                        style="color: #FF9F43"
                        icon="EditIcon"
                        svgClasses="w-5 h-5 hover:text-warning stroke-current"
                        class="mr-2"
                        @click="editData(tr)"
                      />
                    </vx-tooltip>
                    <vx-tooltip v-if="user_role ==='manager' || user_role ==='admin'" :text="$t('Supprimer')" color="danger">
                      <feather-icon
                        @click="deleteData(tr.id)"
                        style="color: red"
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="mr-2"
                      />
                    </vx-tooltip>
  
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
      </vs-table>

    <vs-popup
    v-if="activePrompt2" 
      fullscreen 
      class="calendar-event-dialog"
      title="Ajouter une carte"
      :active.sync="activePrompt2">
      <div class="clearfix">
      <div class="vx-row">

        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">

        <p>Code<b style="color: #ff6141">*</b></p>
        <vs-input
        v-validate="'required'"
        data-vv-validate-on="blur"
        name="code"
        v-model="code"
        class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first("code") }}</span>
        
        <!-- NOM ET PRENOMS-->
        <p>Nom & Prénoms <b style="color: #ff6141">*</b></p>
        <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="fullName"
          v-model="fullName"
        class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('fullName')">{{ errors.first("fullName") }}</span>

        <p>{{ $t("dateOfIssue") }} <b style="color: #ff6141">*</b></p>
        <flat-pickr 
          :config="configdateTimePicker" 
          label-placeholder=" Date"
          v-model="dateOfIssue"
          v-validate="'required'"
          name="dateOfIssue"
        class="w-full" />
        <span class="text-danger text-sm" v-show="errors.has('dateOfIssue')">{{$t("dateOfIssue_error")}}</span>

        <p>{{ $t("rateClinic") }}<b style="color: #ff6141" >*</b></p>
        <vs-input-number
            :min="0" 
            v-validate="'required'"
            v-model="rateClinic"
            data-vv-validate-on="blur"
            name="rateClinic"
        class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('rateClinic')">{{$t("rateClinic_error")}}</span>
            
        <p>{{ $t("rateHospitals") }}<b style="color: #ff6141" >*</b></p>
        <vs-input-number
        :min="0" 
        v-validate="'required'"
        v-model="rateHospitals"
        data-vv-validate-on="blur"
        name="rateHospitals"
        class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('rateHospitals')">{{$t("rateHospitals_error")}}</span>
            
        </div>
  
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">

        <p> Genre <b style="color: #ff6141" >*</b> </p>
        <v-select
          v-validate="'required'"
          name="gender"
          :reduce="rep => rep.key"
          class="w-full" label="name"
          v-model="gender"
          :options="genders">
        </v-select>
        <span class="text-danger text-sm" v-show="errors.has('gender')">{{ errors.first("gender") }}</span>
        
        <p>{{ $t("expirationDate") }}<b style="color: #ff6141">*</b></p>
        <flat-pickr 
          :config="configdateTimePicker" 
          label-placeholder=" Date"
          v-model="expirationDate"
          v-validate="'required'"
          name="expirationDate"
        class="w-full" />
        <span class="text-danger text-sm" v-show="errors.has('expirationDate')">{{$t("expirationDate_error")}}</span>

        <p>{{ $t("ratePharmacy") }}<b style="color: #ff6141" >*</b></p>
        <vs-input-number
        :min="0" 
        v-validate="'required'"
        v-model="ratePharmacy"
        data-vv-validate-on="blur"
        name="ratePharmacy"
        class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('ratePharmacy')">{{ $t("ratePharmacy_error") }}</span>
        
        <p>{{ $t("ownerOfPdv") }}<b style="color: #ff6141" >*</b> </p>
        <v-select
          v-validate="'required'"
          name="ownerOfPdv"
          :reduce="rep => rep.id"
          class="w-full" label="fullName"
          v-model="ownerOfPdv"
          :options="ownerOfPdvs">
        </v-select>
        <span class="text-danger text-sm" v-show="errors.has('ownerOfPdv')">{{ $t("ownerOfPdv_error") }}</span>
        
        </div>
        
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
        
        <p>{{ $t("birthdate") }}<b style="color: #ff6141">*</b></p>
        <flat-pickr 
          :config="configdateTimePicker" 
          label-placeholder=" Date"
          v-model="birthdate"
          v-validate="'required'"
          name="birthdate"
        class="w-full" />
        <span class="text-danger text-sm" v-show="errors.has('birthdate')">{{ errors.first("birthdate") }}</span>

        <p>{{ $t("rateHealthyCenter") }}<b style="color: #ff6141" >*</b></p>
        <vs-input-number
        :min="0" 
        v-validate="'required'"
        v-model="rateHealthyCenter"
        data-vv-validate-on="blur"
        name="rateHealthyCenter"
        class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('rateHealthyCenter')">{{ $t("rateHealthyCenter_error") }}</span>
        
        <p>{{ $t("rateMedicalOffice") }}<b style="color: #ff6141" >*</b></p>
        <vs-input-number
        :min="0" 
        v-validate="'required'"
        v-model="rateMedicalOffice"
        data-vv-validate-on="blur"
        name="rateMedicalOffice"
        class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('rateMedicalOffice')">{{ $t("rateMedicalOffice_error") }}</span>
        
        <p> Photo <b style="color: #ff6141">*</b>  </p>
        <div class="upload-img mt-2">
          <input  v-validate="'required'" name="name_file6" type="file" class="hidden" ref="uploadImgInput6" @change="updateCurrImg6"  accept="image/*">
          <span><b style="color:orange">{{ name_file6 }}</b></span>
          <vs-button  @click="$refs.uploadImgInput6.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
          <span class="text-danger text-sm" v-show="errors.has('name_file6')">{{ $t("name_file6") }}</span>
        </div>
        </div>

      </div>
      <vs-button color="success"  class="float-right mt-4"  @click="valider" >Envoyer</vs-button>
     </div>
    </vs-popup>
    
    <vs-popup
    v-if="activePrompt1" 
      fullscreen 
      class="calendar-event-dialog"
      title="Modifier une carte"
      :active.sync="activePrompt1">
      <div class="clearfix">
        <div class="vx-row">

          <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">

          <p>Code<b style="color: #ff6141">*</b></p>
          <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="code"
          v-model="code"
          class="w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first("code") }}</span>
          
          <!-- NOM ET PRENOMS-->
          <p>Nom & Prénoms <b style="color: #ff6141">*</b></p>
          <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="fullName"
          v-model="fullName"
          class="w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('fullName')">{{ errors.first("fullName") }}</span>

          <p>{{ $t("dateOfIssue") }} <b style="color: #ff6141">*</b></p>
          <flat-pickr 
          :config="configdateTimePicker" 
          label-placeholder=" Date"
          v-model="dateOfIssue"
          v-validate="'required'"
          name="dateOfIssue"
          class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('dateOfIssue')">{{$t("dateOfIssue_error")}}</span>

          <p>{{ $t("rateClinic") }}<b style="color: #ff6141" >*</b></p>
          <vs-input-number
          :min="0" 
          v-validate="'required'"
          v-model="rateClinic"
          data-vv-validate-on="blur"
          name="rateClinic"
          class="w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('rateClinic')">{{$t("rateClinic_error")}}</span>

          <p>{{ $t("rateHospitals") }}<b style="color: #ff6141" >*</b></p>
          <vs-input-number
          :min="0" 
          v-validate="'required'"
          v-model="rateHospitals"
          data-vv-validate-on="blur"
          name="rateHospitals"
          class="w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('rateHospitals')">{{$t("rateHospitals_error")}}</span>

          </div>

          <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">

          <p> Genre <b style="color: #ff6141" >*</b> </p>
          <v-select
          v-validate="'required'"
          name="gender"
          :reduce="rep => rep.key"
          class="w-full" label="name"
          v-model="gender"
          :options="genders">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('gender')">{{ errors.first("gender") }}</span>

          <p>{{ $t("expirationDate") }}<b style="color: #ff6141">*</b></p>
          <flat-pickr 
          :config="configdateTimePicker" 
          label-placeholder=" Date"
          v-model="expirationDate"
          v-validate="'required'"
          name="expirationDate"
          class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('expirationDate')">{{$t("expirationDate_error")}}</span>

          <p>{{ $t("ratePharmacy") }}<b style="color: #ff6141" >*</b></p>
          <vs-input-number
          :min="0" 
          v-validate="'required'"
          v-model="ratePharmacy"
          data-vv-validate-on="blur"
          name="ratePharmacy"
          class="w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('ratePharmacy')">{{ $t("ratePharmacy_error") }}</span>

          <p>{{ $t("ownerOfPdv") }}<b style="color: #ff6141" >*</b> </p>
          <v-select
          v-validate="'required'"
          name="ownerOfPdv"
          :reduce="rep => rep.id"
          class="w-full" label="fullName"
          v-model="ownerOfPdv"
          :options="ownerOfPdvs">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('ownerOfPdv')">{{ $t("ownerOfPdv_error") }}</span>

          </div>  

          <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">

          <p>{{ $t("birthdate") }}<b style="color: #ff6141">*</b></p>
          <flat-pickr 
          :config="configdateTimePicker" 
          label-placeholder=" Date"
          v-model="birthdate"
          v-validate="'required'"
          name="birthdate"
          class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('birthdate')">{{ errors.first("birthdate") }}</span>

          <p>{{ $t("rateHealthyCenter") }}<b style="color: #ff6141" >*</b></p>
          <vs-input-number
          :min="0" 
          v-validate="'required'"
          v-model="rateHealthyCenter"
          data-vv-validate-on="blur"
          name="rateHealthyCenter"
          class="w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('rateHealthyCenter')">{{ $t("rateHealthyCenter_error") }}</span>

          <p>{{ $t("rateMedicalOffice") }}<b style="color: #ff6141" >*</b></p>
          <vs-input-number
          :min="0" 
          v-validate="'required'"
          v-model="rateMedicalOffice"
          data-vv-validate-on="blur"
          name="rateMedicalOffice"
          class="w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('rateMedicalOffice')">{{ $t("rateMedicalOffice_error") }}</span>
          <p> Photo <b style="color: #ff6141"></b>  </p>
          <div class="upload-img mt-2">
            <input  name="name_file6" type="file" class="hidden" ref="uploadImgInput6" @change="updateCurrImg6"  accept="image/*">
            <span @click="showImage()"><b style="color:orange">{{ name_file6 }}</b></span>
            <vs-button  @click="$refs.uploadImgInput6.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
            <span class="text-danger text-sm" v-show="errors.has('name_file6')">{{ $t("name_file6") }}</span>
          </div>
          </div>

        </div>
      <vs-button color="success"  class="float-right mt-4"  @click="valider" >Envoyer</vs-button>
     </div>
    </vs-popup>

    <vs-popup
    v-if="activePrompt3" 
    fullscreen
    class="calendar-event-dialog"
    title="Détails de la carte"
    :active.sync="activePrompt3">
    <div class="clearfix">
      <div class="vx-row">

        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">

          <p>Code<b style="color: #ff6141">*</b></p>
          <vs-input
          disabled
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="code"
          v-model="code"
          class="w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first("code") }}</span>

        <!-- NOM ET PRENOMS-->
        <p>Nom & Prénoms <b style="color: #ff6141">*</b></p>
        <vs-input
          disabled
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="fullName"
          v-model="fullName"
        class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('fullName')">{{ errors.first("fullName") }}</span>

        <p>{{ $t("dateOfIssue") }} <b style="color: #ff6141">*</b></p>
        <flat-pickr 
          disabled
          :config="configdateTimePicker" 
          label-placeholder=" Date"
          v-model="dateOfIssue"
          v-validate="'required'"
          name="dateOfIssue"
        class="w-full" />
        <span class="text-danger text-sm" v-show="errors.has('dateOfIssue')">{{$t("dateOfIssue_error")}}</span>

        <p>{{ $t("rateClinic") }}<b style="color: #ff6141" >*</b></p>
        <vs-input-number
            disabled
            :min="0" 
            v-validate="'required'"
            v-model="rateClinic"
            data-vv-validate-on="blur"
            name="rateClinic"
        class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('rateClinic')">{{$t("rateClinic_error")}}</span>
            
        <p>{{ $t("rateHospitals") }}<b style="color: #ff6141" >*</b></p>
        <vs-input-number
        disabled
        :min="0" 
        v-validate="'required'"
        v-model="rateHospitals"
        data-vv-validate-on="blur"
        name="rateHospitals"
        class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('rateHospitals')">{{$t("rateHospitals_error")}}</span>
        </div>
  
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">

        <p> Genre <b style="color: #ff6141" >*</b> </p>
        <v-select
          disabled
          v-validate="'required'"
          name="gender"
          :reduce="rep => rep.key"
          class="w-full" label="name"
          v-model="gender"
          :options="genders">
        </v-select>
        <span class="text-danger text-sm" v-show="errors.has('gender')">{{ errors.first("gender") }}</span>
        
        <p>{{ $t("expirationDate") }}<b style="color: #ff6141">*</b></p>
        <flat-pickr 
          disabled
          :config="configdateTimePicker" 
          label-placeholder=" Date"
          v-model="expirationDate"
          v-validate="'required'"
          name="expirationDate"
        class="w-full" />
        <span class="text-danger text-sm" v-show="errors.has('expirationDate')">{{$t("expirationDate_error")}}</span>

        <p>{{ $t("ratePharmacy") }}<b style="color: #ff6141" >*</b></p>
        <vs-input-number
        disabled
        :min="0" 
        v-validate="'required'"
        v-model="ratePharmacy"
        data-vv-validate-on="blur"
        name="ratePharmacy"
        class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('ratePharmacy')">{{ $t("ratePharmacy_error") }}</span>
        
        <p v-if="user_role !=='agent'">{{ $t("ownerOfPdv") }}<b style="color: #ff6141" >*</b> </p>
        <v-select
          v-if="user_role !=='agent'"
          disabled
          v-validate="'required'"
          name="ownerOfPdv"
          :reduce="rep => rep.id"
          class="w-full" label="fullName"
          v-model="ownerOfPdv"
          :options="ownerOfPdvs">
        </v-select>
        <span v-if="user_role !=='agent'" class="text-danger text-sm" v-show="errors.has('ownerOfPdv')">{{ $t("ownerOfPdv_error") }}</span>
        
        </div>
        
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
        
        <p>{{ $t("birthdate") }}<b style="color: #ff6141">*</b></p>
        <flat-pickr 
          disabled
          :config="configdateTimePicker" 
          label-placeholder=" Date"
          v-model="birthdate"
          v-validate="'required'"
          name="birthdate"
        class="w-full" />
        <span class="text-danger text-sm" v-show="errors.has('birthdate')">{{ errors.first("birthdate") }}</span>

        <p>{{ $t("rateHealthyCenter") }}<b style="color: #ff6141" >*</b></p>
        <vs-input-number
        disabled
        :min="0" 
        v-validate="'required'"
        v-model="rateHealthyCenter"
        data-vv-validate-on="blur"
        name="rateHealthyCenter"
        class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('rateHealthyCenter')">{{ $t("rateHealthyCenter_error") }}</span>
        
        <p>{{ $t("rateMedicalOffice") }}<b style="color: #ff6141" >*</b></p>
        <vs-input-number
        disabled
        :min="0" 
        v-validate="'required'"
        v-model="rateMedicalOffice"
        data-vv-validate-on="blur"
        name="rateMedicalOffice"
        class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('rateMedicalOffice')">{{ $t("rateMedicalOffice_error") }}</span>
        </div>

      </div>
      <vs-button color="primary"  class="float-right mt-4"  @click="quit" >Quitter</vs-button>
     </div>
    </vs-popup>

    </div>
  </template>
  
<script>
// For custom error message
import { Validator } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
const French = require('flatpickr/dist/l10n/fr.js').default.fr
const link = 'https://form.moozistudio.com/transvieformstandard/uploads/'
const link2 = 'https://form.moozistudio.com/transvieformstandard/uploads/'

const dict = {
  custom: {
    code: {
      required: 'Le champ code est obligatoire'
    },
    fullName: {
      required: 'Le champ nom et prénom est obligatoire'
    },
    gender: {
      required: 'Le champ genre est obligatoire'
    },
    birthdate: {
      required: 'Le champ date d\'anniversaire est obligatoire'
    },
    phoneNumber: {
      required: 'Le champ téléphone est obligatoire',
      min: 'Au minimum 8 caractères.',
      max: 'Au minimum 8 caractères.'
    }
  }
}
  
// register custom messages
Validator.localize('en', dict)
  
export default {
  components: {
    flatPickr
      
  },
  data () {
    return {
      checkId:[],
      updatecreate:false,
      id:null,
      user_role:'',
      activePrompt3:false,
      activePrompt2:false,
      activePrompt1:false,
      GENDERCHOICES: {
        male: 'Masculin',
        female:'Feminin'
      },
      cards:[],
      ownerOfPdvs: [],
      selected: [],
      itemsPerPage: 20,
      isMounted: false,
      addNewDataSidebar: false,
      sidebarData: {},
      configdateTimePicker: {
        enableTime: 'fasle',
        dateFormat: 'Y-m-d',
        locale: French
      },
      genders: [
        {
          key: 'male',
          name: 'Masculin'
        },
        {
          key: 'female',
          name: 'Féminin'
        }
      ],
      code:'',
      gender:'',
      phoneNumber:'',
      memberPicture:'',
      name_file6:'',
      dataImg6:'',
      fullName:'',
      birthdate:'',
      dateOfIssue:'',
      expirationDate:'',
      rateHealthyCenter:0,
      rateClinic:0,
      ratePharmacy:0,
      rateMedicalOffice:0,
      rateHospitals:0,
      ownerOfPdv:'',
      time_GMT:''
    }
  },
 
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.cards.length
    }
  },
  methods: {
    cardPrint (data) {    
      this.invoiceFicheData = JSON.parse(JSON.stringify(data))
      this.image_adherent = (`${ link  }${(data.picture).substring(46)}`)
      this.$router.push(this.$route.query.redirect || '/CardsView')

    },
    getOrderStatusColor1 (date) {
      if ((new Date(date))<(new Date(this.time_GMT)))   return 'danger'
      if ((new Date(date))>(new Date(this.time_GMT))) return 'success'
      return 'primary'
    },
    addNewData () {
      this.intialise()
      this.activePrompt2 = true
    },
    intialise () {
      this.id = null
      this.code = ''
      this.fullName = ''
      this.gender = ''
      this.birthdate = ''
      this.memberPicture = ''
      this.name_file6 = ''
      this.dataImg6 = ''
      this.birthdate = ''
      this.dateOfIssue = ''
      this.expirationDate = ''
      this.rateHealthyCenter = 0
      this.rateClinic = 0
      this.ratePharmacy = 0
      this.rateMedicalOffice = 0
      this.rateHospitals = 0
      this.ownerOfPdv = ''

    },
    updateCurrImg6 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg6 = input.target.files
        this.name_file6 = input.target.files[0].name
      }
    },
    quit () {
      this.activePrompt3 = false
      this.intialise()
    },
    Voirpicture (data) {
      window.open(`${ link  }${(data.picture).substring(46)}`, '_blank')

      /*  if (this.updatecreate === true) {
        const cutImage = data.picture.split('uploads/')[1]
        window.open(`${ link2  }${(cutImage)}`, '_blank')

      } else if (this.updatecreate === false) {
        window.open(`${ link  }${(data.picture).substring(52)}`, '_blank')
      } */
    },
    valider () {
      // if (!this.validateForm || !this.checkLogin()) return
      // eslint-disable-next-line no-unused-vars
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading()
          const formdata = new FormData()
         
          if (this.dataImg6) {
            formdata.append('picture', this.dataImg6[0], this.name_file6)
          }
          formdata.append('code', this.code)
          formdata.append('fullName', this.fullName)
          formdata.append('gender', this.gender)
          formdata.append('birthdate', this.birthdate) 
          formdata.append('dateOfIssue', this.dateOfIssue) 
          formdata.append('expirationDate', this.expirationDate) 
          formdata.append('rateHealthyCenter', this.rateHealthyCenter) 
          formdata.append('rateClinic', this.rateClinic) 
          formdata.append('ratePharmacy', this.ratePharmacy) 
          formdata.append('rateMedicalOffice', this.rateMedicalOffice) 
          formdata.append('rateHospitals', this.rateHospitals) 
          formdata.append('ownerOfPdv', this.ownerOfPdv) 


          let url = 'cards/'
          let methods = 'post'
          if (this.id) {
            url += `${this.id}/`
            methods = 'put'
          }
          this.$http[methods](url, formdata)
          //this.$http.post('ownerOfPdvs/', formdata)
            .then(() => {
              this.activePrompt1 = false
              this.activePrompt2 = false
              this.getAllcards()
              this.intialise()
              this.$validator.reset()
              this.$vs.loading.close()
            })
            .catch((error) => {
              const clefs = Object.keys(error.response.data)
              for (let i = 0; i < clefs.length; i++) {
                const item = clefs[i]
  
                if (item === 'fullName') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ Nom & prénoms ',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'gender') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ genre',
                    color: 'warning',
                    position: 'top-center'
                  })
                }  else if (item === 'birthdate') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ date de naissance',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'dateOfIssue') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ date d\'émission',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'expirationDate') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ date d\'expiration',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'rateHealthyCenter') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ taux centre de santé',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'rateClinic') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ taux clinique',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'ratePharmacy') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ taux pharmacie',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'rateMedicalOffice') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ taux médical',
                    color: 'warning',
                    position: 'top-center'
                  })
                }  else if (item === 'rateHospitals') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ taux hôpital',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'picture') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ date de naissance',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'ownerOfPdv') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ inscrit',
                    color: 'warning',
                    position: 'top-center'
                  })
                }
              }
              this.$vs.notify({
                time: 8000,
                title: 'ENREGISTREMENT',
                text: 'L\'enregistrement a échoué',
                color: 'danger',
                position: 'top-right'
              })
              this.$vs.loading.close()
            })
            
        }
          
      })
    },
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`cards/${id}/`)
            .then(response => {
              this.getAllcards()
              //this.REMOVE_ITEM_PRODUIT(id)
              window.getPrendTaCom.success(this.$t('Suppressioncard'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('Suppression')})
            })
        }
      })
  
    },

    openfiles (file) {
      if (this.updatecreate === true) {
        window.open(`${ link2  }${file}`, '_blank')
      } else if (this.updatecreate === false) {
        window.open(`${ link  }${file}`, '_blank')
      }
    },
    VoirData (data) {
      this.intialise()
      this.activePrompt3 = true
      this.id = data.id
      this.code = data.code
      this.fullName = data.fullName
      this.gender = data.gender
      this.birthdate = data.birthdate
      this.dateOfIssue = data.dateOfIssue
      this.expirationDate = data.expirationDate
      this.rateHealthyCenter = data.rateHealthyCenter
      this.rateClinic = data.rateClinic
      this.ratePharmacy = data.ratePharmacy
      this.rateMedicalOffice = data.rateMedicalOffice
      this.rateHospitals = data.rateHospitals
      this.ownerOfPdv = data.ownerOfPdv.id
    },
    editData (data) {
      this.intialise()
      this.activePrompt1 = true
      this.id = data.id
      this.code = data.code
      this.name_file6 = data.picture.substring(46)
      this.fullName = data.fullName
      this.gender = data.gender
      this.birthdate = data.birthdate
      this.dateOfIssue = data.dateOfIssue
      this.expirationDate = data.expirationDate
      this.rateHealthyCenter = data.rateHealthyCenter
      this.rateClinic = data.rateClinic
      this.ratePharmacy = data.ratePharmacy
      this.rateMedicalOffice = data.rateMedicalOffice
      this.rateHospitals = data.rateHospitals
      this.ownerOfPdv = data.ownerOfPdv.id

    },
    showImage () {
      window.open(`${ link  }${this.name_file6}`, '_blank')
    },
    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    },
    getAllownerOfPdvs () {
      this.$vs.loading()
      this.$http.get('ownerOfPdvs/')
        .then((response) => {
          this.ownerOfPdvs = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllcards () {
      this.$vs.loading()
      this.$http.get('cards/')
        .then((response) => {
          this.cards = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  created () {
    window.getProspect = this
    document.title = 'TransVie | PDV'
    const date = new Date()
    this.time_GMT = date.toISOString().split('T')[0]
    this.user_role = JSON.parse(localStorage.getItem('userInfo')).role
    this.getAllcards()
    this.getAllownerOfPdvs()
   

  },
  mounted () {
    this.isMounted = true
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
  
      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
            box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
            td{
              padding: 20px;
              &:first-child{
                border-top-left-radius: .5rem;
                border-bottom-left-radius: .5rem;
              }
              &:last-child{
                border-top-right-radius: .5rem;
                border-bottom-right-radius: .5rem;
              }
            }
            td.td-check{
              padding: 20px !important;
            }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  